import React, { useEffect, useState, useRef } from "react"
import Delete from "@spectrum-icons/workflow/Delete"
import Draw from "@spectrum-icons/workflow/Draw"
import ChevronRight from "@spectrum-icons/workflow/ChevronRight"
import "react-schedule-job/dist/index.css"
import "../index.css"
import {
    Flex,
    SearchField,
    ActionGroup,
    Item,
    Text,
    Content,
    IllustratedMessage,
    Badge,
    Heading,
    TableView,
    TableHeader,
    Column,
    TableBody,
    Cell,
    Row,
    Dialog,
    DialogTrigger,
    Divider,
    ActionButton,
    ButtonGroup,
    Button,
    View,
    Well,
    TextField,
    TextArea,
    ListView,
    LabeledValue,
    Text as Write,
} from "@adobe/react-spectrum"
import DeleteDialog from "./deleteDialog"
import {
    deleteRoles,
    getRoles,
    isRoleActive,
    saveRole,
    addNewRole,
    getRolesList,
    getAllPolicies,
    addNewRoles,
    getUsersDetailsPermissions,
    getAllUsers,
} from "../utilities/api/admin-api"
import AddRoleDialog from "./AddRoleDialog"
import { connect } from "react-redux"
import ErrorDialog from "./ErrorDialog"
import { notify } from "../constants/constants"
import { renderEmptyState } from "../utilities/components/CommonUseComponents"
import { AddUpdatedRoleDialog } from "./AddUpdatedRoleDialog"
import PermissionDetails from "./PermissionDetails"
import Alert from "@spectrum-icons/workflow/Alert"
import { fromDate } from "@internationalized/date"


const roleFormStructure = {
    name: "",
    description: "",
    policyIds: [],
}

function mapStateToProps(state) {
    // console.log({state});
    return {
        user: state.login.userInfo,
        rolesAndPermissions: state.login.rolesAndPermissions,
    }
}

const RoleManagement = ({ user, rolesAndPermissions }) => {
    const havingDeletePermission = rolesAndPermissions["ADMIN"]?.delete?.filter(
        (ele) => ele.subService === "CARE ROLES"
    )[0]?.resources.type
    const havingEditPermission = rolesAndPermissions["ADMIN"]?.edit?.filter(
        (ele) => ele.subService === "CARE ROLES"
    )[0]?.resources.type
    // const havingViewPermission = rolesAndPermissions["ADMIN"]?.view?.filter((ele) => ele.subService === "CARE ROLES")[0]?.resources.type
    const havingAddPermission = rolesAndPermissions["ADMIN"]?.add?.filter(
        (ele) => ele.subService === "CARE ROLES"
    )[0]?.resources.type

    const [allRoles, setAllRoles] = useState([])
    const [filteredRoles, setFilteredRoles] = useState([])
    const [errorMessage, setErrorMessage] = useState("")
    const [currentText, setCurrentText] = useState("")
    const [selectedRoles, setSelectedRoles] = useState(new Set())
    const [operation, setOperation] = useState("")
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)
    const [isdeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const [showError, setShowError] = useState(false)
    const [isNewAddDialogue, setIsNewAddDialogue] = useState(false)
    const [policiesList, setPoliciesList] = useState([])
    const [selectedPoliciesList, setSelectedPoliciesList] = useState(new Set())
    const [formData, setFormData] = useState(roleFormStructure)
    const newEditData = useRef(null)
    const [currentPoliciesText, setCurrentPoliciesText] = useState("")
    const [filteredPolicies, setFilteredPolicies] = useState([])
    const [usersWithRoleID, setUsersWithRoleID] = useState([])
    const [alreadyExistingRoles, setAlreadyExistingRoles] = useState([])
    const [alreadyExist, setAlreadyExist] = useState(false)

    const columns = [
        { name: "Name", key: "name" },
        { name: "Description", key: "description" },
        { name: "Actions", key: "actions" },
    ]


    // useEffect(() => {
    //     if((formData.name && fromDate.name !== "") && formData.name?.toLowerCase().trim() !== newEditData.current.name.toLowerCase().trim() &&  alreadyExistingRoles?.includes(formData.name.toLowerCase().trim())) {
    //         setAlreadyExist(true)
    //     }else{
    //         setAlreadyExist(false)
    //     }
    // }, [formData.name])

    
    useEffect(() => {
        if (!formData?.name) {
            setAlreadyExist(false);
            return;
        }

        const isEditMode = currentRoleSelected?.current !== null;
        if (isEditMode && formData?.name.toLowerCase().trim() === currentRoleSelected?.current?.name.toLowerCase().trim()) {            
            setAlreadyExist(false);
            return;
        }
    
        const normalizedName = formData?.name?.toLowerCase()?.trim();
        const nameAlreadyExists = alreadyExistingRoles?.some(role => role?.toLowerCase() === normalizedName);    
        setAlreadyExist(nameAlreadyExists);
    }, [formData.name, alreadyExistingRoles]);
    

    const currentRoleSelected = useRef("")
    const editData = useRef(false)
    const isLoading = useRef(false)
    const editNewData = useRef(null)

    function getAllRolesData() {
        isLoading.current = true
        getRolesList()
            .then((roles) => {
                isLoading.current = false
                setAlreadyExistingRoles(roles)
                setAllRoles(roles)
                setFilteredRoles(roles)
            })
            .catch((error) => {
                isLoading.current = false
                console.log(error)
                //setErrorMessage("Error occured!")
            })
    }

    const getAllUpdatedRoles = () => {
        isLoading.current = true
        getRolesList()
            .then((resp) => {
                const modifiedResp = resp.map((role) => {
                    role.description =
                        role.description || "This is the fake description"
                    return role
                })
                isLoading.current = false
                setAlreadyExistingRoles(
                    modifiedResp?.map((role) => role?.name.toLowerCase().trim())
                )
                setAllRoles(modifiedResp)
                setFilteredRoles(modifiedResp)
            })
            .catch((err) => {
                isLoading.current = false
                console.log(err)
                //setErrorMessage("Error occured!")
            })
            .finally(() => {
                isLoading.current = false
            })
    }

    const getPolicies = () => {
        getAllPolicies(user?.userid)
            .then((response) => {
                setFilteredPolicies(response)
                setPoliciesList(response)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                console.log("completed")
            })
    }

    const getAllUsersAPI = () => {
        getAllUsers(user?.userid)
            .then((response) => {
                setUsersWithRoleID(response)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                console.log("completed")
            })
    }

    useEffect(() => {
        // getAllRolesData()
        getAllUsersAPI()
        getAllUpdatedRoles()
        getPolicies()
    }, [])

    useEffect(() => {
        console.log({ selectedPoliciesList, policiesList })
    }, [selectedPoliciesList, policiesList])

    const getPermissionBadge = (permissionList) => {
        const badges = []
        permissionList.forEach((element) =>
            badges.push(
                <Badge aria-label={element} key={element} variant="neutral">
                    {element}
                </Badge>
            )
        )
        return badges
    }

    const getOrganizationsBadge = (orgs) => {
        const orgBadges = []
        orgs.forEach((element) =>
            orgBadges.push(
                <Badge
                    aria-label={element.name}
                    key={element.name}
                    variant={element.badgeVariant}
                >
                    {element.name}
                </Badge>
            )
        )
        return orgBadges
    }

    const getDivisionsBadge = (divs, orgs) => {
        const divisionBadges = []
        divs.forEach((division) => {
            const divBadgecolor = orgs
                .filter((org) => org.id == division.orgId)
                .map((org) => org.badgeVariant)
            divisionBadges.push(
                <Badge
                    aria-label={division.name}
                    key={division.name}
                    variant={divBadgecolor}
                >
                    {division.name}
                </Badge>
            )
        })
        return divisionBadges
    }

    const getPermissions = (permissionList) => {
        return permissionList.join(",")
    }

    const getOrganizations = (orgs) => {
        const orgNames = []
        orgs?.forEach((element) => {
            orgNames.push(element.name)
        })
        return orgNames.join(",")
    }

    const getDivisions = (divs) => {
        const divNames = []
        divs.forEach((element) => {
            divNames.push(element.name)
        })
        return divNames.join(",")
    }

    const filterFunction = (value) => {
        if (!allRoles.length) return 
        const filteredRoles = allRoles.filter(
            (role) =>
                role.name.toLowerCase().includes(value.toLowerCase()) ||
                role.description.toLowerCase().includes(value.toLowerCase())
            // Uncomment and modify these lines if the functions for `orgs`, `divisions`, and `permissions` are needed
            // getOrganizations(role.orgs).toLowerCase().includes(value.toLowerCase()) ||
            // getDivisions(role.divisions).toLowerCase().includes(value.toLowerCase()) ||
            // getPermissions(role.permissions).toLowerCase().includes(value.toLowerCase())
        )
        setFilteredRoles(filteredRoles)
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (currentText) {
                filterFunction(currentText)
            } else {
                setFilteredRoles(allRoles)
            }
        }, 500)
        return () => clearTimeout(timeout)
    }, [currentText, allRoles])

    const filterRoles = (value) => {
        setCurrentText(value)
    }

    
    const performAction = (value, role) => {
        switch (value) {
            // case "add":
            //     editData.current = null
            //     setOperation("Add Role")
            //     setIsAddDialogOpen(true)
            //     break
            case "add":
                currentRoleSelected.current = null
                setOperation("add")
                setFormData({
                    name: "",
                    description: "",
                    policyIds: []
                })
                setSelectedPoliciesList(new Set())
                setIsNewAddDialogue(true)
                break
            case "edit":
                currentRoleSelected.current = role
                setOperation("edit")
                // setIsAddDialogOpen(true)
                setSeletedValue(role)
                break
            case "delete":
                currentRoleSelected.current = role.id
                setIsDeleteDialogOpen(true)
                break
            case "bulkDelete":
                currentRoleSelected.current = role
                setIsDeleteDialogOpen(true)
                break
        }
    }

    function deleteSelectedRoles(rolesToDelete) {
        isLoading.current = true
        deleteRoles(rolesToDelete, user?.userid)
            .then((res) => {
                isLoading.current = false
                notify("Role deleted successfully", "success")
                getAllRolesData()
                setSelectedRoles(new Set())
            })
            .catch((error) => {
                isLoading.current = false
                console.log(error)
                setErrorMessage("Error occured!")
            })
    }

    // function isSelectedRoleActive(rolesToDelete) {
    //     console.log("rolesToDelete", rolesToDelete);

    //     isLoading.current = true
    //     isRoleActive(rolesToDelete)
    //         .then((res) => {
    //             if (res === true) {
    //                 isLoading.current = false
    //                 setErrorMessage("Selected Role is active")
    //                 window.scrollTo(0, document.body.scrollHeight)
    //             } else {
    //                 deleteSelectedRoles(rolesToDelete)
    //             }
    //         })
    //         .catch((error) => {
    //             isLoading.current = false
    //             console.log(error)
    //             setErrorMessage("Error occured!")
    //             window.scrollTo(0, document.body.scrollHeight)
    //         })
    // }

    // console.log("Role selected",currentRoleSelected.current);

    const closeDeleteDialog = (isDeletedConfirmed) => {
        setIsDeleteDialogOpen(false)

        if (isDeletedConfirmed) {
            if (usersWithRoleID.length) {
                const usersWithRole = usersWithRoleID.filter((user) =>
                    user.roleIds.includes(currentRoleSelected.current)
                )

                if (usersWithRole.length > 0) {
                    notify(
                        "The role is assigned to one or more users. Please unassign the role from all users and try again",
                        "warn"
                    )
                    return
                }
            }

            let rolesToDelete = []
            if (currentRoleSelected.current instanceof Set) {
                currentRoleSelected.current.forEach((roleId) =>
                    rolesToDelete.push(roleId)
                )
            } else {
                rolesToDelete = [currentRoleSelected.current]
            }

            if (selectedRoles === "all") {
                rolesToDelete = allRoles.map((role) => role.name)
            }

            deleteSelectedRoles(rolesToDelete)
        }
    }

    const closeAddRoleDialog = (isAddConfirmed, roleData, error) => {
        setIsAddDialogOpen(false)
        setShowError(error)
        if (isAddConfirmed) {
            isLoading.current = true

            saveRole(roleData, user?.userid)
                .then((res) => {
                    isLoading.current = false
                    if (res) {
                        notify("Permissions updated successfully", "success")
                        getAllRolesData()
                    }
                })
                .catch((error) => {
                    isLoading.current = false
                    notify("error", "Error occured")
                    console.log("add error", error)
                })
        }
    }

    const setSeletedValue = (value) => {
        setSelectedPoliciesList(new Set(value.policyIds))
        setFormData({
            ...value
        })
        newEditData.current = value
        setIsNewAddDialogue(true)
    }

    const updateFormData = (key, data) => {
        const updatedValue = Object.assign(formData, { [key]: data })
        setFormData({ ...updatedValue })
    }

    const getCell = (columnKey, item) => {
        // console.log({ columnKey, item })

        switch (columnKey) {
            case "actions":
                return (
                    <Cell>
                        <ActionGroup
                            onAction={(value) => performAction(value, item)}
                            disabledKeys={[
                                !havingEditPermission && "edit",
                                !havingDeletePermission && "delete",
                            ]}
                        >
                            <Item key="edit">
                                <Draw />
                            </Item>

                            <Item key="delete">
                                <Delete />
                            </Item>
                        </ActionGroup>
                    </Cell>
                )
            case "name":
                return (
                    <Cell>
                        <Flex
                            direction="row"
                            maxWidth={200}
                            gap={8}
                            wrap="wrap"
                        >
                            <Heading level={6}>{item[columnKey]}</Heading>
                        </Flex>
                    </Cell>
                )
            case "description":
                return (
                    <Cell>
                        <View>
                            {/* <ActionButton width="100%" isQuiet onPress={()=>setSeletedValue(item)} UNSAFE_className="pointer" UNSAFE_style={{padding:"0px 20px 0px 0px"}}> */}
                            <Flex
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                // width={"100%"}
                                gap={8}
                                wrap="nowrap"
                            >
                                <View>{item[columnKey]}</View>
                                {/* <View>
                                            <ChevronRight size="XS" />
                                        </View> */}
                            </Flex>
                            {/* </ActionButton> */}
                        </View>
                    </Cell>
                )
            default:
                return <Cell>{item[columnKey]}</Cell>
        }
    }

    const closeErrorDialog = () => {
        setShowError(false)
    }

    const onCloseHandlerNewRole = (value) => {
        if (value) {
            // console.log({ value })
            value.policies.forEach((policy) => {
                Object.keys(policy.permissions).forEach((permissionType) => {
                    policy.permissions[permissionType] = policy?.permissions[
                        permissionType
                    ].filter((permission) => {
                        return !(
                            permission.tab === "" &&
                            Object.keys(permission.resources).length === 0
                        )
                    })
                })
            })
            value.policies = value.policies.filter(
                (policy) => policy.service !== ""
            )
            addNewRole(user?.userid, value)
                .then((res) => {
                    isLoading.current = false
                    if (res) {
                        notify("Permissions updated successfully", "success")
                        getAllUpdatedRoles()
                    }
                })
                .catch((error) => {
                    isLoading.current = false
                    notify("error", "Error occured")
                    console.log("add error", error)
                })
        }
        newEditData.current = null
        setIsNewAddDialogue(false)
    }

    const closeModal = (value) => {
        if (value) {
            const newValue = { ...value , name: value.name.trim() , description: value.description.trim() }
            // console.log({ value })
            newValue.policyIds = Array.from(selectedPoliciesList)
            addNewRoles(user?.userid, newValue)
                .then((response) => {
                    notify("Role added successfully", "success")
                    getAllUpdatedRoles()
                })
                .catch((err) => {})
                .finally(() => {})
        }
        // setFormData(JSON.parse(JSON.stringify(roleFormStructure)))
        // setSelectedPoliciesList(new Set())
        // setIsNewAddDialogue(false)
        setFormData({
            name: "",
            description: "",
            policyIds: []
        })
        setSelectedPoliciesList(new Set())
        setCurrentText("") 
        setCurrentPoliciesText("") 
        setIsNewAddDialogue(false)
    }

    // const filterPolicyFunction = (value) => {
    //     if (policiesList.length) {
    //         const filteredPolicies = allRoles.filter(
    //             (role) =>
    //                 role.name.toLowerCase().includes(value.toLowerCase()) ||
    //                 role.services.map(service=>service.service).join(", ").toLowerCase().includes(value.toLowerCase())
    //         )
    //         setFilteredPolicies(filteredPolicies)
    //     }
    // }

    // useEffect(() => {
    //     const timeOut = setTimeout(() => {
    //         if (currentPoliciesText) filterPolicyFunction(currentPoliciesText)
    //         else setFilteredPolicies(policiesList)
    //     }, 500)
    //     return () => clearTimeout(timeOut)
    // }, [currentText])

    // const filterPolicies = (value) => {
    //     setCurrentPoliciesText(value);
    // }

    const filterPolicyFunction = (searchValue) => {
        if (!searchValue.trim()) {
            setFilteredPolicies(policiesList)
            return
        }

        const searchTerm = searchValue.toLowerCase().trim()

        const filteredPolicies = policiesList.filter((policy) => {
            const nameMatch = policy.name.toLowerCase().includes(searchTerm)

            const servicesMatch = policy.services.some((service) =>
                service.service.toLowerCase().includes(searchTerm)
            )

            return nameMatch || servicesMatch
        })

        setFilteredPolicies(filteredPolicies)
    }

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            filterPolicyFunction(currentPoliciesText)
        }, 500)

        return () => clearTimeout(timeoutId)
    }, [currentPoliciesText, policiesList])

    const handleFilterChange = (value) => {
        setCurrentPoliciesText(value)
    }

    return (
        <>
            <div align="center">
                <Heading variant="pageTitle">Care Roles Details</Heading>
            </div>

            <div
                style={{
                    padding: "25px",
                    border: "0.8px solid",
                    borderRadius: "0.7em",
                    marginTop: "30px",
                }}
            >
                <Flex
                    direction="row"
                    gap="size-100"
                    UNSAFE_className="searchMobile"
                >
                    <SearchField
                        isQuiet
                        placeholder="Search role"
                        onClear={() => setCurrentText("")}
                        onChange={filterRoles}
                        aria-label="Search"
                        value={currentText}
                        width={"20%"}
                    />
                    <ActionGroup
                        width={"80%"}
                        alignItems="end"
                        onAction={(value) =>
                            performAction(value, selectedRoles)
                        }
                        disabledKeys={[
                            !havingAddPermission && "add",
                            !havingDeletePermission && "bulkDelete",
                            selectedRoles.size === 0 && "bulkDelete"
                        ]}
                    >
                        <Item key="add">+ Add</Item>
                        <Item key="bulkDelete">
                            <Delete />
                            <Text>Delete</Text>
                        </Item>
                    </ActionGroup>
                </Flex>

                <TableView
                    aria-label="Care Roles"
                    selectionMode={
                        !havingDeletePermission ? "none" : "multiple"
                    }
                    selectedKeys={selectedRoles}
                    onSelectionChange={setSelectedRoles}
                    height={"45vh"}
                    renderEmptyState={renderEmptyState}
                    density="spacious"
                    // overflowMode="wrap"
                    // disabledKeys={["1"]}
                >
                    <TableHeader columns={columns}>
                        {(column) => (
                            <Column
                                key={column.key}
                                align={
                                    column.key === "actions" ? "end" : "start"
                                }
                                minWidth={
                                    ["permissions"].indexOf(column.key) > -1
                                        ? "40%"
                                        : "10%"
                                }
                            >
                                {column.name}
                            </Column>
                        )}
                    </TableHeader>
                    <TableBody
                        items={filteredRoles}
                        loadingState={isLoading.current ? "loading" : ""}
                    >
                        {(item) => (
                            <Row key={item.id}>
                                {(columnKey) => getCell(columnKey, item)}
                            </Row>
                        )}
                    </TableBody>
                </TableView>
                {isdeleteDialogOpen && (
                    <DeleteDialog
                        isDialogOpened={isdeleteDialogOpen}
                        handleCloseDialog={(isDeletedConfirmed) =>
                            closeDeleteDialog(isDeletedConfirmed)
                        }
                        entity="Role"
                    />
                )}
            </div>

            {errorMessage && (
                <div
                    style={{
                        fontSize: "large",
                        color: "red",
                        marginTop: "1%",
                    }}
                    align="center"
                >
                    {" "}
                    <b> {errorMessage}</b>
                </div>
            )}
            {isAddDialogOpen && (
                <AddRoleDialog
                    isDialogOpened={isAddDialogOpen}
                    editData={editData.current}
                    handleCloseDialog={(isAddConfirmed, roleData, error) =>
                        closeAddRoleDialog(isAddConfirmed, roleData, error)
                    }
                    operation={operation}
                    allRoles={allRoles}
                />
            )}

            <DialogTrigger
                type="fullscreenTakeover"
                isDismissable={true}
                defaultOpen={false}
                isOpen={isNewAddDialogue}
            >
                <ActionButton ishidden isQuiet></ActionButton>
                {(close) => (
                    <Dialog>
                        <Heading justifySelf={"center"} level={"6"}>
                            Add New Roles
                        </Heading>
                        <Divider />
                        <Content>
                            <Flex UNSAFE_className="mainBoxPermissionFlex dialogue-container">
                                <Flex
                                    UNSAFE_className="leftsideDetails"
                                    alignContent={"center"}
                                    direction={"column"}
                                    gap={"size-100"}
                                    justifyContent={"space-between"}
                                >
                                    {/* Left Details section start */}
                                    <Flex direction={"column"} gap={"size-100"}>
                                        {/* <View>Redirect Back</View> */}
                                        <View marginBottom={"-14px"}>
                                            <h6>Role Name</h6>
                                        </View>
                                        <TextField
                                            value={formData?.name || ""}
                                            width={"100%"}
                                            onChange={(value) =>
                                                updateFormData("name", value)
                                            }
                                            placeholder="Role"
                                        />
                                        <View marginBottom={"-14px"}>
                                            <h6>Description</h6>
                                        </View>
                                        <TextArea
                                            value={formData?.description || ""}
                                            width={"100%"}
                                            height={"300px"}
                                            onChange={(value) =>
                                                updateFormData(
                                                    "description",
                                                    value
                                                )
                                            }
                                            placeholder="write few lines about the role..."
                                        />
                                    </Flex>
                                    <View>
                                    <View>
                                {alreadyExist && (
                                    <Write
                                        UNSAFE_style={{
                                            color: "#8B5700", // Darker, more subdued brown for text
                                            fontWeight: 600,
                                            backgroundColor: "#FFF4D4", // Softer yellow background
                                            padding: "8px",
                                            borderRadius: "4px",
                                            display: "block",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <Alert size="XS" /> Role name already
                                        exists
                                    </Write>
                                )}
                            </View>
                                        <ButtonGroup width={"100%"}>
                                            <Button
                                                variant="cta"
                                                width={"100%"}
                                                onPress={() =>
                                                    closeModal(formData)
                                                }
                                                isDisabled={alreadyExist || formData?.name?.trim() === "" || formData?.description?.trim() === "" || selectedPoliciesList.size === 0 }
                                            >
                                                Submit
                                            </Button>
                                            <Button
                                                variant="secondary"
                                                width={"100%"}
                                                onPress={() =>
                                                    closeModal(false)
                                                }
                                            >
                                                Cancel
                                            </Button>
                                        </ButtonGroup>
                                    </View>
                                    {/* Left Details section end */}
                                </Flex>
                                <Flex
                                    UNSAFE_className="rightsideDetails"
                                    gap={"size-100"}
                                >
                                    <View width={"70%"}>
                                        <Flex
                                            direction={"column"}
                                            gap={"size-100"}
                                            margin={"size-100"}
                                        >
                                            {Array.from(
                                                selectedPoliciesList
                                            ).map((item) => (
                                                <Flex
                                                    width={"100%"}
                                                    justifyContent={
                                                        "space-between"
                                                    }
                                                    UNSAFE_style={{
                                                        background: "#F0F0F0",
                                                        padding: "10px",
                                                    }}
                                                >
                                                    <Flex
                                                        direction={"column"}
                                                        justifyContent={
                                                            "center"
                                                        }
                                                    >
                                                        <LabeledValue
                                                            label="Policy Name"
                                                            value={
                                                                policiesList?.filter(
                                                                    (policy) =>
                                                                        policy.id ==
                                                                        item
                                                                )[0]?.name
                                                            }
                                                        />
                                                    </Flex>
                                                    <Flex
                                                        direction={"column"}
                                                        justifyContent={
                                                            "center"
                                                        }
                                                    >
                                                        <LabeledValue
                                                            label="Policy Services"
                                                            value={policiesList
                                                                ?.filter(
                                                                    (policy) =>
                                                                        policy.id ==
                                                                        item
                                                                )[0]
                                                                ?.services?.map(
                                                                    (service) =>
                                                                        service?.service
                                                                )
                                                                .join(", ")}
                                                        />
                                                    </Flex>
                                                    <Flex></Flex>
                                                </Flex>
                                            ))}
                                        </Flex>
                                    </View>
                                    <View width={"30%"}>
                                        <Flex
                                            direction={"column"}
                                            justifyContent={"center"}
                                            width={"100%"}
                                        >
                                            <SearchField
                                                label="Search Policies"
                                                width={"100%"}
                                                marginBottom={"10px"}
                                                onClear={() =>
                                                    setCurrentPoliciesText("")
                                                }
                                                onChange={handleFilterChange}
                                                aria-label="Search"
                                                value={currentPoliciesText}
                                            />
                                            <ListView
                                                items={filteredPolicies}
                                                selectionMode="multiple"
                                                maxWidth="size-6000"
                                                maxHeight="70vh"
                                                height="auto"
                                                aria-label="Dynamic ListView items example"
                                                selectedKeys={Array.from(
                                                    selectedPoliciesList
                                                )}
                                                onSelectionChange={
                                                    setSelectedPoliciesList
                                                }
                                                renderEmptyState={
                                                    renderEmptyState
                                                }
                                            >
                                                {(item) => (
                                                    <Item key={`${item.id}`}>
                                                        {item.name}
                                                    </Item>
                                                )}
                                            </ListView>
                                        </Flex>
                                    </View>
                                </Flex>
                            </Flex>
                        </Content>
                    </Dialog>
                )}
            </DialogTrigger>

            {showError && (
                <ErrorDialog
                    isDialogOpened={showError}
                    handleCloseDialog={() => closeErrorDialog()}
                    msg="Role already exists"
                />
            )}
        </>
    )
}

export default connect(mapStateToProps)(RoleManagement)
