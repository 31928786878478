import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import "./style.css";

const CustomTooltip = ({ children, content }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [tooltipStyles, setTooltipStyles] = useState({});
  const triggerRef = useRef(null);
  const tooltipRef = useRef(null);


  const calculatePosition = () => {
    if (!triggerRef.current || !tooltipRef.current) return;

    const rect = triggerRef.current.getBoundingClientRect();
    const tooltipRect = tooltipRef.current.getBoundingClientRect();


    const positions = {
      left: rect.left + rect.width / 2 - tooltipRect.width / 2,
      top: rect.top - tooltipRect.height - 8, 
    };

    setTooltipStyles(positions);
  };

  useEffect(() => {
 
    if (isVisible) {
      calculatePosition();
    }

    const handleScroll = () => calculatePosition();
    const handleResize = () => calculatePosition();

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [isVisible]); 

  return (
    <>
      <div
        ref={triggerRef}
        className="tooltips"
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </div>
      {isVisible &&
        ReactDOM.createPortal(
          <div
            ref={tooltipRef}
            className="tooltip-content"
            style={{
              ...tooltipStyles,
              position: "fixed", 
              zIndex: 10,
            }}
          >
            {content}
          </div>,
          document.body
        )}
    </>
  );
};

export default CustomTooltip;
