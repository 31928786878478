import React from "react"
import { Flex, Grid, View, Heading } from "@adobe/react-spectrum"
// import DataProficiencyTable from "./DataProficiencyTable"
// import DataQueueTable from "./DataQueueTable"
import ComparisionTable from "./ComparisionTable"
import ArrowRight from "@spectrum-icons/workflow/ArrowRight"

export function DataComparison({ entry }) {
    console.log("entry", entry)
    const isProficiency = entry?.eventType?.includes("PROFICIENCY")
    const isGroupConfigChange = entry?.eventType?.includes("GROUP_CONFIG_CHANGE")

    return (
        <Flex direction="column" gap="size-300">
            <Grid
                areas={["oldData center newData"]}
                columns={["1fr", "size-800", "1fr"]}
                gap="size-300"
            >
                <View gridArea="oldData">
                    <ComparisionTable
                        data={entry?.oldData || []}
                        title="Previous Data"
                        variant="old"
                        isProficiency={isProficiency}
                        isGroupConfigChange={isGroupConfigChange}
                        // columns={
                        //     isProficiency
                        //         ? ["Attribute Name", "Attribute Value", "Level"]
                        //         : ["Queue Name", "Channel", "Priority", "Delay"]
                        // }
                    />
                </View>
                <Flex
                    gridArea="center"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Heading level={3} margin="0">
                        <ArrowRight />
                    </Heading>
                </Flex>
                <View gridArea="newData">
                    <ComparisionTable
                        data={entry.newData}
                        title="New Data"
                        variant="new"
                        isProficiency={isProficiency}
                        isGroupConfigChange={isGroupConfigChange}
                        // columns={
                        //     isProficiency
                        //         ? ["Attribute Name", "Attribute Value", "Level"]
                        //         : ["Queue Name", "Channel", "Priority", "Delay"]
                        // }
                    />
                </View>
            </Grid>

            <View marginTop="size-400">
               {entry.diff.added && <ComparisionTable
                    data={entry.diff.added}
                    title={"Added Data"}
                    variant={"added"}
                    status={false}
                    isProficiency={isProficiency}
                    isGroupConfigChange={isGroupConfigChange}
                    // columns={
                    //     entry.eventType.includes("PROFICIENCY")
                    //         ? ["Attribute Name", "Attribute Value", "Level"]
                    //         : ["Queue Name", "Channel", "Priority", "Delay"]
                    // }
                />}
               {entry.diff.removed && <ComparisionTable
                    data={entry.diff.removed}
                    title={"Removed Data"}
                    variant={"removed"}
                    status={false}
                    isProficiency={isProficiency}
                    isGroupConfigChange={isGroupConfigChange}
                    // columns={
                    //     entry.eventType.includes("PROFICIENCY")
                    //         ? ["Attribute Name", "Attribute Value", "Level"]
                    //         : ["Queue Name", "Channel", "Priority", "Delay"]
                    // }
                />}
            </View>
        </Flex>
    )
}
