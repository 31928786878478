import {
  Cell,
  Checkbox,
  Column,
  Flex,
  Row,
  TableBody,
  TableHeader,
  TableView,
  TextField,
} from "@adobe/react-spectrum";
import React, { useEffect, useState } from "react";

const initialPermissions = [
  {
    name: "*",
  },
];

const GroupPermissions = ({ permissionList, selectedGroupsRef, tab, service }) => {
  // console.log("Line 21 groupPermissions" ,{permissionList, selectedGroupsRef, tab, service });
  
  const [initialList, setInitialList] = useState(initialPermissions);
  const [searchQuery, setSearchQuery] = useState("");
  const [, setUpdate] = useState(0);

  // console.log("initialList line 25", initialList);
  

  useEffect(() => {
    if (permissionList) {
      if(tab === "GROUP CONFIG" || tab === "USERS" || tab === "SUPERVISORS" || tab === "QUEUE MONITORING" || tab === "OTHERS MONITORING") {
        setInitialList((prev) => [...prev, ...permissionList]);
      }
    }
  }, [permissionList]);
  
  const handleCheckboxChange = (groupName, permissionType) => {
    const updatedGroups = [...selectedGroupsRef.current];
    const selectedGroupIndex = updatedGroups.findIndex((element) => element.tab === tab);

    if (selectedGroupIndex > -1) {
      const selectedGroup = { ...updatedGroups[selectedGroupIndex] };
      const updatedPermissionArray = [...selectedGroup.permissions[permissionType]];
      const groupIndex = updatedPermissionArray.findIndex((element) => element === groupName);

      if (groupIndex < 0) {
        updatedPermissionArray.push(groupName);
      } else {
        updatedPermissionArray.splice(groupIndex, 1);
      }

      selectedGroup.permissions[permissionType] = updatedPermissionArray;
      updatedGroups[selectedGroupIndex] = selectedGroup;
    }

    selectedGroupsRef.current = updatedGroups;
    setUpdate((prev) => prev + 1);
  };

  // const handleAllCheckboxChange = (permissionType) => {
  //   const groupNames = initialList.map((group) => group.name);
  //   const updatedGroups = [...selectedGroupsRef.current];
  //   const selectedGroupIndex = updatedGroups.findIndex((element) => element.tab === tab);

  //   if (selectedGroupIndex > -1) {
  //     const selectedGroup = { ...updatedGroups[selectedGroupIndex] };

  //     const allSelected = groupNames.every((groupName) =>
  //       selectedGroup.permissions[permissionType].includes(groupName)
  //     );

  //     let updatedPermissionArray = allSelected ? [] : [...groupNames];
  //     selectedGroup.permissions[permissionType] = updatedPermissionArray;
  //     updatedGroups[selectedGroupIndex] = selectedGroup;
  //   }

  //   selectedGroupsRef.current = updatedGroups;
  //   setUpdate((prev) => prev + 1);
  // };

  const handleAllCheckboxChange = (permissionType) => {
    const updatedGroups = [...selectedGroupsRef.current];
    const selectedGroupIndex = updatedGroups.findIndex((element) => element.tab === tab);
  
    if (selectedGroupIndex > -1) {
      const selectedGroup = { ...updatedGroups[selectedGroupIndex] };
  
      const allSelected = selectedGroup.permissions[permissionType].includes("*");
  
      selectedGroup.permissions[permissionType] = allSelected ? [] : ["*"];
      updatedGroups[selectedGroupIndex] = selectedGroup;
    }
  
    selectedGroupsRef.current = updatedGroups;
    setUpdate((prev) => prev + 1);
  };
  

  const renderCheckboxCell = (groupName, permissionType) => {
    const selectedGroupIndex = selectedGroupsRef.current.findIndex((element) => element.tab === tab);

    const isChecked =
      selectedGroupIndex > -1
        ? selectedGroupsRef.current[selectedGroupIndex].permissions[permissionType].includes(groupName) ||
          selectedGroupsRef.current[selectedGroupIndex].permissions[permissionType].includes("*")
        : false;

    return (
      <>
        {groupName === "*" ? (
          <>
           <Flex direction="row" gap="size-100">
              <Checkbox
                isEmphasized
                isSelected={isChecked}
                onChange={() => handleAllCheckboxChange(permissionType)}
                aria-label="All"
              >
                All
              </Checkbox>

            </Flex>
          </>
        ) : (
          <Checkbox
          isEmphasized
            isSelected={isChecked}
            onChange={() => handleCheckboxChange(groupName, permissionType)}
            aria-label={permissionType}
          />
        )}
      </>
    );
  };

  const handleSearchChange = (value) => {
    setSearchQuery(value);
  };

  const filteredList = initialList.filter((group) => {
    if (!searchQuery || group.name === "*") return true;
    return group.name.toLowerCase().includes(searchQuery.toLowerCase());
  });

  return (
    tab === "GROUP CONFIG" ? (
      <>
        <TextField
          aria-label="Search"
          placeholder="Search"
          autoFocus={true}
          onChange={(value) => handleSearchChange(value)}
        />
        <TableView aria-label="group permission">
          <TableHeader>
            <Column width="20%" showDivider={true}>Group name</Column>
            <Column>View</Column>
            <Column>Edit</Column>
            <Column>Add</Column>
            <Column showDivider={true}>Delete</Column>
            <Column>Enforce Update</Column>
            <Column>Clone</Column>
          </TableHeader>
  
          <TableBody>
            {filteredList.length > 0 &&
              filteredList.map((group) => (
                <Row key={group.name}>
                  <Cell>{group.name !== "*" && group.name}</Cell>
                  <Cell>{renderCheckboxCell(group.name, "view")}</Cell>
                  <Cell>{renderCheckboxCell(group.name, "edit")}</Cell>
                  <Cell>{renderCheckboxCell(group.name, "add")}</Cell>
                  <Cell>{renderCheckboxCell(group.name, "delete")}</Cell>
                  <Cell>{renderCheckboxCell(group.name, "enforceUpdate")}</Cell>
                  <Cell>{renderCheckboxCell(group.name, "clone")}</Cell>
                </Row>
              ))}
          </TableBody>
        </TableView>
      </>
    ) : (
      <>

          <TextField
            aria-label="Search"
            placeholder="Search"
            autoFocus={true}
            onChange={(value) => handleSearchChange(value)}
          />
          { (tab === "USERS" || tab === "SUPERVISORS" || tab === "QUEUE MONITORING" || tab === "OTHERS MONITORING" || tab === "ORG FAMILY" || tab ==="EXCEPTION RULES") ? (
          <TableView aria-label="group permission">
                  <TableHeader>
                  <Column width="20%" showDivider={true}>{service === "ALERT" ? "Queue Name" : "Group Name"}</Column>
                        <Column>View</Column>
                        <Column>Edit</Column>
                        <Column>Add</Column>
                        <Column showDivider={true}>Delete</Column>
                  </TableHeader>

                <TableBody>
                  {filteredList.length > 0 &&
                    filteredList.map((group) => (
                      <Row key={group.name}>
                        <Cell>{group.name !== "*" && group.name}</Cell>
                        <Cell>{renderCheckboxCell(group.name, "view")}</Cell>
                        <Cell>{renderCheckboxCell(group.name, "edit")}</Cell>
                        <Cell>{renderCheckboxCell(group.name, "add")}</Cell>
                        <Cell>{renderCheckboxCell(group.name, "delete")}</Cell>
                      </Row>
                    ))}
                </TableBody>
          </TableView>
          ): (
            <TableView aria-label="group permission">
                  <TableHeader>
                        <Column>View</Column>
                        <Column>Edit</Column>
                        <Column>Add</Column>
                        <Column showDivider={true}>Delete</Column>
                  </TableHeader>

                <TableBody>
                  {filteredList.length > 0 &&
                    filteredList.map((group) => (
                      <Row key={group.name}>
                        <Cell>{renderCheckboxCell(group.name, "view")}</Cell>
                        <Cell>{renderCheckboxCell(group.name, "edit")}</Cell>
                        <Cell>{renderCheckboxCell(group.name, "add")}</Cell>
                        <Cell>{renderCheckboxCell(group.name, "delete")}</Cell>
                      </Row>
                    ))}
                </TableBody>
          </TableView>

          )}
      </>
    )
  );
};


export default GroupPermissions;
