import React, { useState } from "react"
import {
    View,
    Heading,
    TableView,
    TableHeader,
    TableBody,
    Column,
    Row,
    Badge,
    Cell,
    TextField,
    Button,
    Flex,
} from "@adobe/react-spectrum"
import { notify } from "../../constants/constants"

export default function ComparisionTable({
    columns,
    title,
    variant,
    data,
    isProficiency,
    isGroupConfigChange,
}) {
    console.log("ProficiencyTable")

    const [searchQuery, setSearchQuery] = useState("")
    const [filteredData, setFilteredData] = useState(data)
    const [isCopy, setIsCopy] = useState(false)

    const handleSearch = (query) => {
        setSearchQuery(query)
        if (query) {
            const lowerCaseQuery = query.toLowerCase()
            setFilteredData(
                data.filter((item) =>
                    Object.values(item).some(
                        (value) =>
                            typeof value === "string" &&
                            value.toLowerCase().includes(lowerCaseQuery)
                    )
                )
            )
        } else {
            setFilteredData(data)
        }
    }

    const copyTableContent = () => {
        const tableContent = filteredData
            .map((item) =>
                Object.values(item)
                    .map((val) => (val !== null ? val : ""))
                    .join("\t")
            )
            .join("\n")
        navigator.clipboard.writeText(tableContent)
        setIsCopy(true)
        // alert("Table content copied to clipboard!")
    }

    const renderStatus = (item) => {
        return (
            <Badge
                UNSAFE_style={{
                    backgroundColor: item.bgColor,
                    color: item.color,
                    padding: "4px",
                    borderRadius: "4px",
                    fontSize: "12px",
                    fontWeight: "700",
                }}
            >
                {item.status}
            </Badge>
        )
    }

    return (
        <>
       
            <View borderRadius="medium" overflow="hidden" boxShadow="small">
                <View
                    padding="size-200"
                    UNSAFE_style={{
                        color: "white",
                        backgroundColor:
                            variant === "old"
                                ? "#bcbcbc"
                                : variant === "new"
                                ? "#dbeaff"
                                : variant === "added"
                                ? "#dcfce7"
                                : "#fee2e1",
                    }}
                >
                    <Heading
                        level={3}
                        margin="0"
                        UNSAFE_style={{
                            color:
                                variant === "old"
                                    ? "#464646"
                                    : variant === "new"
                                    ? "#2e467d"
                                    : variant === "added"
                                    ? "#265b3e"
                                    : "#882e2f",
                        }}
                    >
                        {title}
                    </Heading>
                </View>
                <Flex margin="size-200" gap="size-200" alignItems="center">
                <TextField
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearch}
                    width="size-4600"
                />
                {/* <Button variant={isCopy ? "accent" : "secondary" } onPress={copyTableContent}>
                    {!isCopy ?  "Copy Table" : "Copied!"}
                </Button> */}
            </Flex>

                {isProficiency ? (
                    <TableView
                        aria-label={title}
                        overflowMode="wrap"
                        margin="size-200"
                        UNSAFE_style={{ maxWidth: "100%" }}
                        height={200}
                    >
                        <TableHeader>
                            <Column>Attribute Name</Column>
                            <Column>Attribute Value</Column>
                            <Column>Level</Column>
                        </TableHeader>
                        <TableBody>
                            {filteredData?.map((item, index) => (
                                <Row key={index}>
                                    <Cell>{item.attributeName}</Cell>
                                    <Cell>{item.attributeValue}</Cell>
                                    <Cell>{item.level}</Cell>
                                </Row>
                            ))}
                        </TableBody>
                    </TableView>
                ) : isGroupConfigChange ? (
                    <TableView
                        aria-label={title}
                        overflowMode="wrap"
                        margin="size-200"
                        UNSAFE_style={{ maxWidth: "100%" }}
                        height={200}
                    >
                        <TableHeader>
                            <Column>Attribute Name</Column>
                            <Column>Attribute Value</Column>
                            <Column>Level</Column>
                        </TableHeader>
                        <TableBody>
                            {filteredData?.map((item, index) => (
                                <Row key={index}>
                                    <Cell>{item.attributeName}</Cell>
                                    <Cell>{item.attributeValue}</Cell>
                                    <Cell>{item.level}</Cell>
                                </Row>
                            ))}
                        </TableBody>
                    </TableView>
                ) : (
                    <TableView
                        aria-label={title}
                        overflowMode="wrap"
                        margin="size-200"
                        UNSAFE_style={{ maxWidth: "100%" }}
                        height={200}
                    >
                        <TableHeader>
                            <Column>Queue Name</Column>
                            <Column>Channel</Column>
                            <Column>Priority</Column>
                            <Column>Delay</Column>
                        </TableHeader>
                        <TableBody>
                            {filteredData?.map((item, index) => (
                                <Row key={index}>
                                    <Cell>{item.queue_name}</Cell>
                                    <Cell>{item.channel}</Cell>
                                    <Cell>{item.priority}</Cell>
                                    <Cell>{item.delay}</Cell>
                                </Row>
                            ))}
                        </TableBody>
                    </TableView>
                )}
            </View>
        </>
    )
}
